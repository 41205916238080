
import ApiService from "@/core/services/ApiService";
import store from "@/store";
import { defineComponent, ref } from "vue";
import { config } from "@/core/helpers/config";
// import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
// import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "CZPositionReports",
  components: {},
  setup() {
    const isCurrent = ref(1);
    const reportName = ref(1);
    const scenarioName = ref(1);

    interface selectionbox {
      id: string | number;
      name: string;
    }
    const reportType = ref<Array<selectionbox>>([]);
    const scenarioType = ref<Array<selectionbox>>([]);
    const budgetType = ref<Array<selectionbox>>([]);
    budgetType.value.push({ id: 0, name: "current" });
    budgetType.value.push({ id: 1, name: "original" });

    let AccountId = store.getters.AccountId;
    const accountInfo: any = {
      customerId: AccountId,
    };

    ApiService.post("api/FlexProcurement/getproductcontracts/", accountInfo)
      .then(async ({ data }) => {
        const response = await data;

        //  reportType.value.push({
        //     id: response.map((a) => a.ReportId),
        //     name: response.map((a) => a.ReportName),
        //   });

        response.products.forEach((a) => {
          reportType.value.push({ id: a.ReportId, name: a.ReportName });
        });

        //reportType.value = response.products.

        response.contracts.forEach((a) => {
          scenarioType.value.push({ id: a.Id, name: a.contractName });
        });
      })

      .catch((a) => {
        console.log("Api Log Error", a);
      });

    /**
     *@description For downloading a position data report
     */
    function downloadItem() {
      let AccountId = store.getters.AccountId;
      let AccountName = store.getters.AccountName;
      let current = store.getters.layoutConfig("flexibleProcurement.current");
      let FPreportName = store.getters.layoutConfig(
        "flexibleProcurement.reportName"
      );
      let FPscenarioName = store.getters.layoutConfig(
        "flexibleProcurement.scenarioName"
      );

      const accountInfo: any = {
        clientId: AccountId,
        productId: 4,
        reportId: FPreportName,
        scenarioId: FPscenarioName,
        iscurrent: current,
      };
      ApiService.post("api/FlexProcurement/getFlexProcrement", accountInfo)
        .then(async ({ data }) => {
          const response = await data;

          let FileName = response.pdffile;
          let Fname = FileName.substring(FileName.lastIndexOf("\\") + 1).split(
            "."
          );

          let url =
            ApiService.vueInstance.axios.defaults.baseURL +
            "api/Downloadfile/getFile/" +
            AccountName +
            "/pdf/out/" +
            Fname[0] +
            "/" +
            1;
          window.open(url);
        })
        .catch((a) => {
          console.log("Api Log Error", a);
        });
    }

    return {
      budgetType,
      reportType,
      scenarioType,
      downloadItem,
      isCurrent,
      reportName,
      scenarioName,
      config,
    };
  },
});
